@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
footer {
  background: var(--sp-color-footer-primary) no-repeat 950px bottom;
  background-color: var(--sp-color-footer-primary);
  margin-bottom: -10px;
  margin-top: 80px;
  padding: $spacer*2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  color: $white;
  font-family: var(--sp-font-secondary);

	@include media-breakpoint-up(md) {
		padding: $spacer*2 $spacer;
	}

	div[class*="col"] {
		@include media-breakpoint-down(md) {
			padding-left: 0;
		}
	}

  .footer__duke-svg {
    margin-bottom: $spacer;
  }

  a {
    color: $white;
    &:hover {
      text-decoration: underline;
      color: $white;
    }
  }

  .site_name {
    font-size: 22px;
    font-weight: 600;
    text-transform: none;
    margin: -0.2em 0 $spacer 0;
    color: var(--sp-color-footer-logo);

    a {
      color: var(--sp-color-footer-logo);
      text-decoration: none;
    }
  }

  .footer__address,
  .footer__email,
  .footer__phone,
  .footer-contact p {
    position: relative;
    padding-left: 32px;

    span {
      display: block;
    }

    &:before {
      content: "";
      display: block;
      height: 28px;
      width: 28px;
      position: absolute;
      top: 3px;
      left: 0;
    }

    svg {
      color: var(--sp-color-accent-one);
      width: 24px;
      height: 24px;
      position: absolute;
      left: -3px;
    }
  }

  .section-footer-menu {
    @include media-breakpoint-down(md) {
      & > div {
        border-bottom: 1px solid var(--sp-color-accent-one);
      }
    }  
  }

  .footer__right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    > :first-child {
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }
    @include media-breakpoint-down(md) {
      padding-bottom: $spacer;
    }
  }

  // What are these? Are they in use?
  .footer__cta-primary {
    padding: .8em 1.5em;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.28px;
    margin-bottom: $spacer;
    display: inline-block;

    &:hover {
      background-color: darken($orange, 10%);
      text-decoration: none;
    }
  }

  .footer__cta-secondary {
    background-color: var(--sp-color-secondary);
    border: none;
    color: $white;
    padding: .8em 1.5em;
    margin-top: $spacer*0.5;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1.28px;
    display: inline-block;

    &:hover {
      // background-color: darken(var(--sp-color-secondary), 10%);
      text-decoration: none;
    }
  }

  .heading {
    color: $white;
    font-size: 1rem;
    margin-bottom: 0;
    font-weight: 700;
  }

  .footer__social-icons-wrapper {
    margin-top: $spacer;
    margin-bottom: $spacer;
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-up(md) {
      text-align: center;
    }

    a {
      height: 40px;
      width: 40px;
      padding: 7px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: var(--sp-color-footer-social);
      margin: 0.5em 1em 0.5em 0;
      border-radius: 50%;

      @include media-breakpoint-up(md) {
        margin: 0.5em 1em 0.5em 0;
      }

      &:hover {
        background-color: var(--sp-color-accent-two);
        transition: background-color 0.2s ease-out;
      }

      svg {
        width: 100%;
        height: 100%;
        color: #fff;

        &:before {
          content: '';
          border-radius: 50%;
          background-color: #fff;
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }

      &[href*="facebook"] {
        padding-top: 8px;
        padding-bottom: 0;
      }

      &[href*="linkedin"] {
        padding: 9px;
      }            
    }
  }

  .separator {
    height: 1px;
    background-color: var(--sp-color-secondary);
    border: none;
    margin: 2em 0;

    @media (max-width: 768px) {
      height: 0px;
    }
  }

  .footer__copyright-links-wrapper {
    a {
      color: $white;
      font-size: 14px;
      text-decoration: none;
      margin-right: 2em;

      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  .copyrightWrapper {
		display: flex;
    align-items: center;
		padding-top: $spacer*2 !important;

    @include media-breakpoint-up(md) {
      border-top: none;
      padding-top: 0;
    }
  }

  .footer__copyright-Drupallinks-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
