@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
header {
  color: $white;
  background-color: var(--sp-color-header-primary);

  @include media-breakpoint-down(md) {
    overflow-x: hidden;
  }

  .search-form {
    background: var(--sp-color-header-search-background);
    height: 48px;
    width: 100%;

    @include media-breakpoint-up(lg) {
      order: 3;
      width: 30%;
      padding-top: 2px;
      padding-bottom: 1px;
    }

    form {
      padding: 0;
      width: 100%;
      display: flex;
      align-items: center;
    }

    input[type="search"] {
      font-family: var(--sp-font-secondary);
      font-weight: 400;
      font-style: italic;
      font-size: 1.1rem;
      background-color: transparent;
      color: var(--sp-color-header-search-text);
      flex-grow: 1;
      border: none;
      height: 100%;
      text-align: left;
      padding: 0 $spacer*1.3;
      -webkit-font-smoothing: antialiased;

      &:focus {
        box-shadow: none;
      }

      &::-webkit-search-cancel-button {
        display: none;
      }

      &::placeholder {
        color: var(--sp-color-header-search-text);
      }

      @include media-breakpoint-up(lg) {
        padding-right: 6px;
      }
    }

    button[type="submit"] {
      color: var(--sp-color-header-search-icon);
      padding: 5px 9px;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  .navbar-brand {
    text-decoration: none;
    margin: 0;
    white-space: normal;

    @include media-breakpoint-down(xl) {
      margin-bottom: 10px;
    }

    &.logo-mobile {
      width: 100%;
      margin: 0 $spacer;
    }
  }
}

.logo {
  &-area-wrapper {
    padding: 10px 0;
    width: 100%;
  }

  &-mobile {
    display: block;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &-desktop {
    display: none;

    @include media-breakpoint-up(lg) {
      width: 65%;
      display: flex;
      align-items: center;
      order: 2;
    }
  }
}

.logo__duke-svg {
  width: 100%;
  margin-top: auto;
  max-height: 56px;
  margin-bottom: 3px;

  @include media-breakpoint-down(md) {
    min-width: 100px;
  }
}

.logo__sitename {
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  color: var(--sp-color-header-logo);
  font-family: var(--sp-font-secondary);
  font-weight: 600;
  line-height: 1;
  width: 100%;
  -webkit-font-smoothing: antialiased;

  &--name,
  &--prefix {
    font-size: 1.7rem;
    line-height: 1;

    @include media-breakpoint-up(sm) {
      font-size: 1.875rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 37px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 40px;
    }
  }
}

.utility_menu_wrapper {
  order: 3;
  padding: $spacer * 1.5;

  @include media-breakpoint-up(lg) {
    order: 1;
    padding: 0;
    margin-left: auto;
  }
}

.header_menu_wrapper {
  order: 2;
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 100%;
    order: 4;
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
  }

  .container {
    @include media-breakpoint-down(lg) {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }
  }
}

.header_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 200;

  .container {
    @include media-breakpoint-down(lg) {
      max-width: 100%;
      padding: 0;
    }
  }

  .navbar-collapse {
    @include media-breakpoint-up(lg) {
      order: 1;
    }
  }

  .navbar {
    width: 100%;
    padding: 0;
    flex-flow: wrap;
  }

  .navbar-nav {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    @include media-breakpoint-up(lg) {
      align-items: center;
    }
  }
}

.break {
  flex-basis: 100%;
  height: 0;
  order: 2;
}

// Dark skin
header {
  &.dark {
    background-color: var(--sp-color-header-primary);

    .logo__duke-svg {
      filter: invert(100%) sepia(3%) saturate(11%) hue-rotate(16deg)
        brightness(103%) contrast(104%);
    }
  }

  // Light skin
  &.light {
    background-color: $white;

    .logo__sitename {
      color: var(--sp-color-secondary);
    }

    .logo__duke-svg {
      filter: invert(10%) sepia(36%) saturate(6227%) hue-rotate(217deg)
        brightness(102%) contrast(107%);
    }
  }
}
